import './App.css';
import loadingGif from './assets/loading.gif'

function App() {
  return (
    <div className="App">
       <header className="App-header">
        <img src = {loadingGif} alt="loading..." className = "loadingImage"/>
        <p>Come back later please.</p>
      </header>
    </div>
  );
}

export default App;
